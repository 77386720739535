export default [
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/index'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/my/coupon',
    name: 'my-coupon',
    component: () => import('@/views/my/coupon'),
    meta: {
      title: '我的券包'
    }
  },
  {
    path: '/my/richtext',
    name: 'my-richtext',
    component: () => import('@/views/my/richtext'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/my/set',
    name: 'my-set',
    component: () => import('@/views/my/set'),
    meta: {
      title: '设置'
    }
  },
  {
    path: '/my/imgview',
    name: 'my-imgview',
    component: () => import('@/views/my/imgview'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/my/address',
    name: 'my-address',
    component: () => import('@/views/my/address'),
    meta: {
      title: '收货地址'
    }
  },
  {
    path: '/my/address-set',
    name: 'my-address-set',
    component: () => import('@/views/my/address-set'),
    meta: {
      title: '收货地址'
    }
  }
]
