import {
  Lazyload,
  Toast,
  Dialog,
  Button,
  Field,
  Icon,
  Uploader,
  Empty,
  NavBar,
  PullRefresh,
  Tab,
  Tabs,
  Sticky,
  Popup,
  Rate,
  List,
  Overlay,
  CountDown,
  Collapse,
  CollapseItem,
  ActionSheet,
  CellGroup,
  Cell,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Swipe,
  SwipeItem,
  Picker,
  DatetimePicker,
  Image as VanImage,
  Loading,
  NoticeBar,
  Search,
  TreeSelect,
  Form,
  Notify,
  Badge,
  Progress,
  Calendar,
  ImagePreview,
  Step,
  Steps,
  Area
} from 'vant'

export default (Vue) => {
  Vue.use(Lazyload)
    .use(Toast)
    .use(Dialog)
    .use(Button)
    .use(Icon)
    .use(Field)
    .use(Uploader)
    .use(Empty)
    .use(NavBar)
    .use(PullRefresh)
    .use(Tab)
    .use(Tabs)
    .use(Sticky)
    .use(Popup)
    .use(Rate)
    .use(List)
    .use(Overlay)
    .use(CountDown)
    .use(Collapse)
    .use(CollapseItem)
    .use(Swipe)
    .use(SwipeItem)
    .use(Picker)
    .use(DatetimePicker)
    .use(ActionSheet)
    .use(CellGroup)
    .use(Field)
    .use(Cell)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(RadioGroup)
    .use(Radio)
    .use(VanImage)
    .use(Loading)
    .use(NoticeBar)
    .use(Search)
    .use(TreeSelect)
    .use(Form)
    .use(Notify)
    .use(Badge)
    .use(Progress)
    .use(Calendar)
    .use(ImagePreview)
    .use(Step)
    .use(Steps)
    .use(Area)
}
