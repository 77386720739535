<template>
  <van-pull-refresh
    class="refresh"
    v-model="isLoading"
    @refresh="onRefresh"
  >
    <div class="me-page">
      <z-header ref="header">
        <template #left>
          <div @click="goMy">点击进入个人中心</div>
        </template>
      </z-header>
      <!-- 动态组件 -->
      <component
        v-for="item in list"
        :key="item.clinicTopicCode"
        :is="getComponentName(item.activityComponentType)"
        :config="getItem(item)"
      >
      </component>
      <!-- 广告弹窗 -->
      <van-popup v-model="showAdvert" :close-on-click-overlay="false">
        <div class="popup-box">
          <img class="popup-box_banner" :src="dataAdvert.imageUrl" @click="onJumpLink" />
          <div class="popup-box_close">
            <img class="popup-box_close-icon" :src=" require('@/assets/close-icon.png')" @click="onCloseAdvert"/>
          </div>
        </div>
      </van-popup>
    </div>
  </van-pull-refresh>
</template>

<script>

import banner from '@/views/components/banner/index.vue'
import chipArea from '@/views/components/chip-area/index.vue'
import kingKongArea from '@/views/components/king-kong-area/index.vue'
import tagPage from '@/views/components/tag-page/index.vue'
import articleList from '@/views/components/article-list/index.vue'
import functionalArea from '@/views/components/functional-area/index.vue'
import titlePartition from '@/views/components/title-partition/index.vue'
import position from '@/views/components/position/index.vue'
import child from '@/views/components/child/index.vue'
import kids from '@/views/components/kids/index.vue'
import notice from '@/views/components/notice/index.vue'
import doctorList from '@/views/components/doctor-list/index.vue'
import ZVipCard from '@/components/ZVipCard/index.vue'
import { getClinicTopicPage, getClinicAdInfo, clickClinicAd } from '@/api/index'
import api from '@/utils/api'
import { mapGetters } from 'vuex'
import vip from '@/router/modules/vip'

export default {
  components: {
    banner,
    chipArea,
    kingKongArea,
    tagPage,
    articleList,
    functionalArea,
    titlePartition,
    position,
    child,
    kids,
    notice,
    doctorList,
    ZVipCard
  },
  data() {
    return {
      // 组件列表
      list: [],
      // 下拉刷新
      isLoading: false,
      showAdvert: false, // 首页活动弹窗展示
      dataAdvert: {} // 首页广告
    }
  },
  computed: {
    ...mapGetters([
      'getClinicCode'
    ]),
    // 获取组件数据
    getItem() {
      return (value) => {
        let data = {
          clinicTopicCode: this.clinicTopicCode,
          ...value
        }
        return data
      }
    },
    // 获取组件名称
    getComponentName() {
      return (value) => {
        switch (value) {
          case '1': // 公告
            return 'notice'
          case '2': // 轮播
            return 'banner'
          case '3': // 标题组件
            return 'titlePartition'
          case '4': // 地址组件
            return 'position'
          case '5': // 功能区
            return 'functionalArea'
          case '6': // 医生组件
            return 'doctorList'
          case '7': // 文章区
            return 'articleList'
          case '9': // 金刚区
            return 'kingKongArea'
          case '10': // 瓷片区
            return 'chipArea'
          case '14': // 标签页
            return 'tagPage'
          case '16': // 儿童组件
            return 'kids'
          case '17': // 会员卡组件
            return 'ZVipCard'
          default:
            return
        }
      }
    }
  },

  watch: {
    // $route: {
    //   handler(to, form) {
    //     // 新专题页编号
    //     const newClinicTopinCode = to.query.clinicTopicCode || ''
    //     // 老专题页编号
    //     const clinicTopinCode = this.getClinicTopinCode

    //     // 存在新专题页编号的情况下
    //     if (newClinicTopinCode && (newClinicTopinCode != clinicTopinCode)) {
    //       this.$store.commit('SET_CLINIC_TOPIN_CODE', newClinicTopinCode)
    //     }

    //     if (!this.getClinicTopinCode && !this.getClinicCode) return api.toast('请提供社区或专题页编号')

    //     this.init()
    //     if (to.path == form?.path) {
    //       this.$nextTick(() => {
    //         window.scrollTo(0, 0)
    //       })
    //     }
    //   },

    //   immediate: true
    // }

    $route(to, form) {
      this.list = []
      this.init()
      if (to.path == form.path) {
        this.$nextTick(() => {
          window.scrollTo(0, 0)
        })
      }
    }
  },

  mounted() {
    // 初始化时置顶
    window.scrollTo(0, 0)
  },

  created() {
    this.init()
  },

  methods: {
    // 初始化
    init() {
      if(api.getParam().source !== 'home'){
        this.clinicTopicCode = api.getParam()?.clinicTopicCode || api.getUrlParam({
          key: 'clinicTopicCode'
        }) || null
      }
      if (!this.getClinicCode && !this.clinicTopicCode) return api.toast('请提供社区或专题页编号')
      this.getClinicTopicPage()
      this.getAdvertisement()
    },

    // 获取首页广告信息
    getAdvertisement() {
      // positionType 广告位置(0首页，1专题页，2管理组详情页)
      // positionSubCode 广告详细位置(首页：可不传；专题页：clinicTopicCode；管理组详情页：groupCode)
      let param = {
        positionType: api.getParam()?.clinicTopicCode ? 1 : 0,
        positionSubCode: api.getParam()?.clinicTopicCode ? this.clinicTopicCode : ''
      }
      getClinicAdInfo(param).then(res => {
        this.dataAdvert = res.data || {}
        if (this.dataAdvert.imageUrl != null) {
          this.showAdvert = true
        }
      })
    },

    // 关闭活动弹窗
    onCloseAdvert() {
      this.showAdvert = false
    },

    // 获取详情
    async getClinicTopicPage() {
      try {
        const res = await getClinicTopicPage({
          clinicTopicCode: this.clinicTopicCode
        })
        document.title = res.data.clinicTopicName || '健康服务'
        let arr = res.data.content || []
        // console.log('111', arr)
        if (arr.length > 0) {
          arr.map(item => {
            if (item.activityComponentType == 5) {
              // styleType--样式选择（0-1:N，1-N:1，2-其他数量）
              item.arrImg = item.functionList.slice(1)
              item.maxHeight = item.arrImg.length * item.componentHeight + (item.arrImg.length - 1) * 16
            }
            if (item.activityComponentType == 6) {
              // "sign": 0--隐藏标识（0显示，1隐藏）
              let arrDoc = []
              if (item.doctorList) {
                item.doctorList.map(item => {
                  if (item.sign == 0) {
                    arrDoc.push(item)
                  }
                })
              }
              item.arrDoc = arrDoc
            }
            return item
          })
        }
        this.list = arr
        // console.log('222', arr)
        // this.list = res.data.content || []
      } catch (err) {
        console.log(err)
      }
    },

    // 下拉刷新
    onRefresh() {
      this.init()
      this.$refs.header.init()
      this.isLoading = false
    },

    // 前往个人中心
    goMy() {
      api.jumpPage('my')
    },

    // 广告跳转 linkType: 0内部路由｜1外部链接
    onJumpLink() {
      api.jumpLink(this.dataAdvert)
      this.clickClinicAd()
    },

    // 保存广告点击记录
    clickClinicAd() {
      clickClinicAd({
        adRecordId: this.dataAdvert.adRecordId
      }).then((res) => {
        this.showAdvert = false
      }).catch((err) => {

      })
    }
  }
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.refresh {
  // height: 100%;
}
.me-page {
  min-height: 100vh;
  background: #f3f4f8;
  //padding: 30px 0 50px 0;
  padding-bottom: 50px;
}
::v-deep {
  .article-box {
    .article {
      border: initial !important;
    }
  }
}

/* 广告弹窗 */
.van-popup {
  background-color: transparent !important;
}

.popup-box {
  /* background-color: lightblue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-box_banner {
  height: 750px;
  width: 620px;
  border-radius: 16px;
  object-fit: cover;
}

.popup-box_close {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.popup-box_close-icon {
  width: 70px;
  height: 70px;
}
</style>
