export default [
  {
    path: '/order/online/list',
    name: 'order-online-list',
    component: () => import('@/views/order/online/list.vue'),
    meta: {
      title: '咨询订单管理',
      whitelist: true
    }
  },
  {
    path: '/order/cancel',
    name: 'order-cancel',
    component: () => import('@/views/order/cancel.vue'),
    meta: {
      title: '取消订单',
      whitelist: true
    }
  },
  {
    path: '/order/online/detail',
    name: 'order-online-detail',
    component: () => import('@/views/order/online/detail.vue'),
    meta: {
      title: '订单详情',
      whitelist: true
    }
  },
  {
    path: '/order/evaluate',
    name: 'order-evaluate',
    component: () => import('@/views/order/evaluate.vue'),
    meta: {
      title: '订单评价',
      whitelist: true
    }
  },
  {
    path: '/order/online/pay',
    name: 'order-online-pay',
    component: () => import('@/views/order/online/pay.vue'),
    meta: {
      title: '订单支付'
    }
  },
  {
    path: '/order/medicine/list',
    name: 'order-medicine-list',
    component: () => import('@/views/order/medicine/list.vue'),
    meta: {
      title: '处方订单管理'
    }
  },
  {
    path: '/order/medicine/detail',
    name: 'order-medicine-detail',
    component: () => import('@/views/order/medicine/detail'),
    meta: {
      title: '处方详情'
    }
  },
  {
    path: '/order/other/list',
    name: 'order-other-list',
    component: () => import('@/views/order/other/list.vue'),
    meta: {
      title: '其他订单管理'
    }
  },
  {
    path: '/order/other/detail',
    name: 'order-other-detail',
    component: () => import('@/views/order/other/detail.vue'),
    meta: {
      title: '订单详情',
      whitelist: true
    }
  },
  {
    path: '/order/offline/detail',
    name: 'order-offline-detail',
    component: () => import('@/views/order/offline/detail'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/order/offline/evaluate',
    name: 'order-offline-evaluate',
    component: () => import('@/views/order/offline/evaluate'),
    meta: {
      title: '服务评价'
    }
  },
  {
    path: '/order/offline/steps',
    name: 'order-offline-steps',
    component: () => import('@/views/order/offline/steps'),
    meta: {
      title: '订单详情'
    }
  }
]
