export default [
  {
    path: '/drug',
    name: 'drug',
    component: () => import('@/views/drug/index'),
    meta: {
      title: '处方详情'
    }

  },
  {
    path: '/drug-pay',
    name: 'drug-pay',
    component: () => import('@/views/drug/pay'),
    meta: {
      title: '处方结算'
    }
  },
  {
    path: '/drug-decoction-explain',
    name: 'drug-decoction-explain',
    component: () => import('@/views/drug/decoction-explain'),
    meta: {
      title: '代煎说明'
    }
  }
]
